/**
 * NOTE: This requires `@sentry/nextjs` version 7.3.0 or higher.
 *
 * NOTE: If using this with `next` version 12.2.0 or lower, uncomment the
 * penultimate line in `CustomErrorComponent`.
 *
 * This page is loaded by Nextjs:
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * See:
 *  - https://nextjs.org/docs/basic-features/data-fetching/overview
 *  - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 *  - https://reactjs.org/docs/error-boundaries.html
 */

import * as Sentry from '@sentry/nextjs';
import NextErrorComponent from 'next/error';
import PropTypes from 'prop-types';

import { sendLogsToLogstash } from 'constants/logstash';
import { IS_DEV_NODE_ENV } from 'constants/nodeEnv';
import { loggerToFile, loggerToLogstash } from 'services/Logger';

const CustomErrorComponent = props => {
  // If you're using a Nextjs version prior to 12.2.1, uncomment this to
  // compensate for https://github.com/vercel/next.js/issues/8592
  const { shouldNotReportToSentry, statusCode, title } = props;
  if (!shouldNotReportToSentry) {
    Sentry.captureUnderscoreErrorException(props);
  }

  return <NextErrorComponent statusCode={statusCode} title={title} />;
};

CustomErrorComponent.Theme = {
  navbar: {
    theme: 'light',
  },
};

CustomErrorComponent.getInitialProps = async contextData => {
  const DEFAULT_MESSAGE = 'Sorry, this page cannot be found.';

  const { err, res } = contextData;

  if (!process.browser && (res?.statusCode === 502 || err?.statusCode === 502)) {
    if (IS_DEV_NODE_ENV) loggerToFile.error({ error: err, res });
    if (sendLogsToLogstash) loggerToLogstash.error({ error: err, res });
  }
  const {
    statusCode = 500,
    message = DEFAULT_MESSAGE,
    title,
    statusMessage,
    shouldNotReportToSentry = false,
  } = err || res || {};

  if (res) {
    res.statusCode = statusCode;
  }

  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  // await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return { statusCode, title: message || title || statusMessage, shouldNotReportToSentry };
};

CustomErrorComponent.propTypes = {
  shouldNotReportToSentry: PropTypes.bool.isRequired,
  statusCode: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
};

export default CustomErrorComponent;
